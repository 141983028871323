import React, { Fragment, useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Card, Grid } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  paddingNull: {
    padding: '20px 0px',
    minHeight: 'calc(100vh - 70px)'
    // background: '#ffffff'
  },
  tableWrapper: {
    width: '100%'
  },
  evolWrapper: {
    width: '100%',
    margin: '2%'
  }
}))

const UserList = props => {
  const classes = useStyles()

  const [dataList, setDataList] = useState([])
  const token = sessionStorage.getItem('token') ?? null

  const fetchDataList = async () => {
    try {
      const response = await axios(
        'http://api.sensamind.com/api/get-user-content?action=tutorial',
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      if (response.data?.status === true) {
        setDataList(response.data.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchDataList()
  }, [])

  return (
    <Fragment>
      <Container className={classes.paddingNull}>
        <div className={classes.tableWrapper}>
          <Typography
            component={'h2'}
            style={{ width: '50%' }}
            className={'title'}
          >
            All Tutorial list
          </Typography>
        </div>
        <Grid
          container
          spacing={6}
          alignItems={'flex-start'}
          justifyContent={'space-around'}
        >
          {dataList.length > 0 ? (
            <>
              {dataList.map((item, index) => {
                return (
                  <Grid item md={4} xs={6} key={index}>
                    <Card
                      elevation={0}
                      className={'card'}
                      style={{ paddingBottom: 0 }}
                    >
                      <Typography
                        className={'number'}
                        style={{
                          padding: '0',
                          textTransform: 'capitalize',
                          lineHeight: '44px',
                          marginBottom: '20px'
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography variant={'subtitle2'} color='textPrimary'>
                        {item.description}
                      </Typography>
                      <p
                        style={{
                          padding: '10px 0',
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '10px'
                        }}
                      >
                        <a href={item.link} target='_black'>
                          {item.link}
                        </a>
                        <label>
                          {moment(item.created_at).format('MM-DD-YYYY hh:mm A')}
                        </label>
                      </p>
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          margin: '15px 0'
                        }}
                      >
                        <Button
                          style={{ padding: '10px 20px' }}
                          variant={'contained'}
                          color={'primary'}
                          type='submit'
                          onClick={() => {
                            props.history.push({
                              pathname: '/details/tutorial',
                              state: { data: item }
                            })
                          }}
                        >
                          View details
                        </Button>
                      </div>
                    </Card>
                  </Grid>
                )
              })}
            </>
          ) : (
            <>
              <Typography className={'number'}>No Tutorial found</Typography>
            </>
          )}
        </Grid>
      </Container>
    </Fragment>
  )
}

export default UserList

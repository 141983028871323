import React, { useContext, useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import tableIcons, {
  options
} from '../../../components/universal/table_attributes'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import DataServices from '../../../lib/services/api/data_services'
import { ToastMessageContext } from '../../../lib/contexts/message_context'
import Typography from '@material-ui/core/Typography'
import ManageLabels from '../manage_labels'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    width: '95%'
  },
  subtitle: {
    fontSize: '1.2rem',
    textTransform: 'capitalize',
    padding: '25px 0'
  },
  divClass: {
    display: 'flex',
    background: 'white',
    width: 'calc(100% - 40px)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    gap: '20px'
  }
}))

function EvolutionButtons (props) {
  const classes = useStyles()
  const [buttons, setButtons] = useState([])
  const [type, setType] = useState(0)
  const groupId = props.groupId
  const groupName = props.groupName

  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState(null)
  const [currentSelection, setCurrentSelection] = useState({})

  const tableOptions = {
    ...options,
    paging: false
  }

  const message = useContext(ToastMessageContext)

  const getButtons = () => {
    setLoading(true)
    const params = {
      id: groupId
    }
    DataServices.getData(params, '/admin/buttons').then(res => {
      if (res.success) {
        setButtons(res.buttons)
        setType(res.type)
        setLoading(false)
      } else {
        message.showToastMessage({
          message: 'Something went wrong. Try Again',
          variant: 'error'
        })
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    getButtons()
  }, [groupId])

  const columns = [
    {
      title: 'Evolution Stage',
      field: 'evolution',
      headerStyle: {
        paddingLeft: '3%'
      },
      cellStyle: {
        paddingLeft: '3%'
      }
    },
    {
      title: 'Button ID',
      field: 'node'
    },
    {
      title: 'Button',
      field: 'button_label',
      render: rowData => <span>{rowData.button_label || '-'}</span>
    },
    {
      title: 'Cause 1',
      field: 'cause1'
    },
    {
      title: 'Cause 2',
      field: 'cause2'
    },
    {
      title: 'Cause 3',
      field: 'cause3'
    },
    {
      title: 'Cause 4',
      field: 'cause4'
    },
    {
      title: 'Cause 5',
      field: 'cause5'
    }
  ]

  const handleClose = data => {
    setAction(null)
    setCurrentSelection({})
    getButtons()
  }

  if (type === 1) {
    return (
      <>
        <div className={classes.divClass}>
          <table
            style={{
              width: '50%',
              borderSpacing: '0px',
              border: '1px solid #000'
            }}
          >
            <tr>
              <th
                style={{
                  borderBottom: '1px solid #000',
                  padding: '7px 0px',
                  borderRight: '1px solid #000'
                }}
              >
                Input
              </th>
              <th
                style={{ borderBottom: '1px solid #000', padding: '7px 0px' }}
              >
                Created
              </th>
            </tr>
            {buttons.map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      textAlign: 'center',
                      borderBottom: '1px solid #000',
                      borderRight: '1px solid #000',
                      padding: '7px 0px'
                    }}
                  >
                    {item?.input ?? '-'}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      borderBottom: '1px solid #000',
                      padding: '7px 0px'
                    }}
                  >
                    {moment(item?.created_at).format('LLL') ?? '-'}
                  </td>
                </tr>
              )
            })}
          </table>
        </div>
      </>
    )
  }
  return (
    <div className={classes.tableWrapper}>
      <MaterialTable
        style={{ boxShadow: '1px 0px 20px 5px #7052fb10' }}
        icons={tableIcons}
        title={
          <Typography
            className={classes.subtitle}
            component={'h2'}
            variant={'subtitle2'}
            gutterBottom
          >{`"${groupName}" Evolution Labels`}</Typography>
        }
        columns={columns}
        data={buttons || []}
        isLoading={loading}
        options={tableOptions}
        localization={{
          toolbar: {
            searchPlaceholder: 'Search label by name or id'
          },
          body: {
            emptyDataSourceMessage: 'No Labels found',
            filterRow: {
              filterTooltip: 'Filter'
            }
          },
          header: {
            actions: 'Actions'
          }
        }}
        actions={[
          {
            icon: () => <EditIcon fontSize='small' />,
            tooltip: 'Edit',
            onClick: (event, rowData) => {
              setCurrentSelection(rowData)
              setAction('update')
            }
          }
        ]}
      />
      {action && action === 'update' && (
        <div>
          <ManageLabels
            action={action}
            currentSelection={currentSelection}
            handleClose={handleClose}
          />
        </div>
      )}
    </div>
  )
}

export default EvolutionButtons

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {convertFormDataToJSON} from "../../lib/utils/common_utils";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    formWrapper: {
        background: '#fff',
        width: '400px',
        padding: theme.spacing(3)
    },
    title: {
        textTransform: 'capitalize',
        paddingBottom: theme.spacing(2)
    },
    actionButtons: {
        paddingTop: '20px',
        textAlign: 'right',
        '& button': {
            marginRight: '20px',
            textTransform: 'capitalize',
        }
    },
    backdrop: {
        zIndex: theme.zIndex.dialog + 5,
    },
}));

function ManageDataDialog(props) {
    const classes = useStyles();
    const {handleClose, handleSubmit, children, loading, title, type} = props;

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        handleSubmit(convertFormDataToJSON(formData));
    }

    return (
        <>
            <Dialog
                maxWidth={"md"}
                open={true}
                onClose={handleClose}
                aria-labelledby="create-form"
            >
                <DialogContent>
                    {type !== 'delete' ? <div className={classes.formWrapper}>
                            <Typography variant="subtitle2" className={classes.title}>
                                {title || "Add Data"}
                            </Typography>
                            <form onSubmit={handleFormSubmit}>
                                {children}
                                <div className={classes.actionButtons}>
                                    <Button disabled={loading} type="button" variant={"contained"}
                                            onClick={() => handleClose()}>Cancel</Button>
                                    <Button disabled={loading} type="submit" variant={"contained"} color="primary"
                                            startIcon={loading && <CircularProgress
                                                color={"primary"} fontSize={20}/>}>{loading ? "Please wait..." : type}</Button>
                                </div>
                            </form>
                        </div> :
                        <div className={classes.formWrapper}>
                            {children}
                            <div className={classes.actionButtons}>
                                <Button disabled={loading} type="button" variant={"contained"}
                                        onClick={handleClose}>Cancel</Button>
                                <Button disabled={loading} type="button" variant={"contained"} color="primary"
                                        onClick={handleSubmit} startIcon={loading && <CircularProgress
                                    color={"primary"} fontSize={20}/>}>{loading ? "Removing Details" : "Yes, Confirm"}</Button>
                            </div>
                        </div>
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ManageDataDialog;
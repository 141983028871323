import React, {useContext, useState} from "react";
import ManageDataDialog from "../../components/universal/dialog_component";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DataServices from "../../lib/services/api/data_services";
import {ToastMessageContext} from "../../lib/contexts/message_context";

function NotificationMessage(props) {

    const {handleClose, currentSelection, topic} = props;
    const [loading, setLoading] = useState(false);
    const message = useContext(ToastMessageContext);

    const handleSubmit = (formData) => {
        setLoading(true);
        if (currentSelection?.id) {
            formData['user_id'] = currentSelection?.id;
        }
        if (topic) {
            formData['topic'] = topic;
        }
        DataServices.postData(formData, '/admin/send-notification-message').then((res) => {
            if (res.success) {
                message.showToastMessage({message: "Notification Sent Successfully", variant: "success"});
                setLoading(false);
                handleClose();
            } else {
                setLoading(false);
                message.showToastMessage({
                    message: res.error ? res.error : "Something went wrong. Try again",
                    variant: "error"
                });
            }
        })
    }


    return (
        <ManageDataDialog title={"Send Notification"} handleSubmit={handleSubmit} handleClose={handleClose}
                          loading={loading} type={"Send >"}>
            <Grid container spacing={4} alignItems={"center"} justify={"center"}>
                <Grid item xs={12}>
                    <TextField
                        name={"title"}
                        label={"Notification Title"}
                        required
                        fullWidth
                        inputProps={{minLength: 3, maxLength: 56}}
                        variant="outlined"
                    />
                    <TextField
                        name={"body"}
                        label={"Notification Message"}
                        required
                        fullWidth
                        rows={5}
                        inputProps={{minLength: 5, maxLength: 156}}
                        multiline
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </ManageDataDialog>
    )
}

export default NotificationMessage;
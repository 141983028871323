import React, {useContext, useEffect, useState} from "react";
import MaterialTable from "material-table";
import tableIcons, {options} from "../../components/universal/table_attributes";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import DataServices from "../../lib/services/api/data_services";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import Typography from "@material-ui/core/Typography";
import ManageLabels from "./manage_labels";

const useStyles = makeStyles(theme => ({
    tableWrapper:{
        margin:'3%',
    },
}))


function LabelListing(props) {
    const classes = useStyles();
    const [labels,setLabels] = useState([]);
    const [tableMeta,setTableMeta] = useState({
        page:1,
        total:15,
        pageSize:15,
        searchText:''
    });
    const [loading,setLoading] = useState(false);
    const [action,setAction] = useState(null);
    const [currentSelection,setCurrentSelection] = useState({});
    const {page,total,pageSize,searchText} = tableMeta;
    const tableOptions = {...options,
        searchText:searchText
    };

    const message = useContext(ToastMessageContext);

    const getLabels = () => {
        setLoading(true);
        DataServices.getData({},'/admin/labels').then((res)=>{
            if(res.success){
                setLabels(res.labels);
                setLoading(false);
            }
            else {
                message.showToastMessage({message:"Something went wrong. Try Again",variant:"error"});
                setLoading(false);
            }
        })
    }

    useEffect(()=>{
        getLabels();
    },[]);

    const columns = [
        {
            title: 'Button Label',
            field: 'button_label',
            width:'15%',
            render: rowData => <span>{rowData.button_label || "-"}</span>
        },
        {
            title: 'Cause 1',
            field: 'cause1',
            render: rowData => <span>{rowData.cause1 || "-"}</span>
        },
        {
            title: 'Cause 2',
            field: 'cause2',
            render: rowData => <span>{rowData.cause2 || "-"}</span>
        },
        {
            title: 'Cause 3',
            field: 'cause3',
            render: rowData => <span>{rowData.cause3 || "-"}</span>
        },
        {
            title: 'Cause 4',
            field: 'cause4',
            render: rowData => <span>{rowData.cause4 || "-"}</span>
        },
        {
            title: 'Cause 5',
            field: 'cause5',
            render: rowData => <span>{rowData.cause5 || "-"}</span>
        },
    ];

    const handleClose = (data) => {
        setAction(null);
        setCurrentSelection({});
        getLabels();
    }

    return (
        <div className={classes.tableWrapper}>
            <Typography component={"h2"} className={"title"}>Labels</Typography>
            <MaterialTable
                style={{boxShadow:'1px 0px 20px 5px #7052fb10'}}
                icons={tableIcons}
                title={""}
                columns={columns}
                data={labels||[]}
                isLoading={loading}
                options={tableOptions}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Search anything'
                    },
                    body: {
                        emptyDataSourceMessage: 'No labels found',
                        filterRow: {
                            filterTooltip: 'Filter'
                        },
                    },
                    header:{
                        actions:"Actions"
                    }
                }}
                actions={[
                    {
                        icon: ()=><EditIcon fontSize="small" />,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                            setCurrentSelection(rowData);
                            setAction('update');
                        }
                    },
                    {
                        icon: ()=><AddIcon color="primary"/>,
                        tooltip: 'Add',
                        isFreeAction:true,
                        onClick: (event) => {
                            setCurrentSelection({});
                            setAction('create');
                        }
                    }
                ]}
            />
            {action && <ManageLabels action={action} handleClose={handleClose} currentSelection={currentSelection} />}
        </div>
    )
}

export default LabelListing;

import React, {useEffect, useState} from "react";
import BarChart from 'react-apexcharts';
import {flattenArrayOfObjectsToArray} from "../../lib/utils/common_utils";

function AllCauseGraph(props) {

    let _isMounted = false;
    const {stats} = props;
    const [causeStat, setCauseStat] = useState({
        cause_counts: [],
        cause_labels: []
    });

    useEffect(() => {
        _isMounted = true;
        let newStats = {
            cause_counts: [],
            cause_labels: []
        };
        const clicks = stats.cause_clicks;
        if (clicks && clicks.length > 0) {
            newStats = {
                ...newStats,
                cause_labels: flattenArrayOfObjectsToArray(clicks, 'cause'),
                cause_counts: flattenArrayOfObjectsToArray(clicks, 'total', true)
            }
        }
        setCauseStat(newStats);
        return (() => {
            _isMounted = false
        })

    }, [stats.cause_clicks,stats.total])

    return (
        <div>
            <BarChart
                options={{
                    chart: {
                        type: 'bar',
                        width: "100%",
                        height: 350,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            dataLabels: {
                                position: 'bottom',
                            },
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 1,
                        colors: ['#fff']
                    },
                    xaxis: {
                        title: {
                            text: 'Cause'
                        },
                        categories: causeStat.cause_labels || []
                    },
                    yaxis: {
                        title: {
                            text: 'No of Clicks'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    responsive: [
                        {
                            breakpoint: 1000,
                            options: {
                                chart: {
                                    type: 'bar',
                                    width: "100%",
                                    height: 650,
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: true,
                                        columnWidth: '55%',
                                        endingShape: 'rounded'
                                    }
                                },
                                legend: {
                                    position: "top"
                                },
                                xaxis: {
                                    title: {
                                        text: 'No of Clicks'
                                    },
                                },
                                yaxis: {
                                    title: {
                                        text: 'Cause'
                                    }
                                },
                            }
                        }
                    ]
                }}
                series={[{
                    name: "Button Clicks",
                    data: causeStat.cause_counts
                }]}
                type="bar"
                height={350}
            />
        </div>
    )
}

export default AllCauseGraph;


import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import PasswordVisible from "@material-ui/icons/Visibility";
import PasswordHide from "@material-ui/icons/VisibilityOff";
import {validateEmail} from "../../lib/utils/common_utils";
import UserIcon from "@material-ui/icons/PersonOutlined";
import LockIcon from "@material-ui/icons/LockOutlined";
import useAuthStyles from "./auth_styles";
import DataServices from "../../lib/services/api/data_services";
import Header from "../../components/universal/header";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";


export default function SignIn(props) {
    const classes = useAuthStyles();
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [mask, setMask] = useState(true);
    const [info, setInfo] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = formData.get('eid');
        const password = formData.get('secret_phrase');
        if (!validateEmail(email.trim())) {
            setError({...error, email: true});
            return;
        }

        const postData = {
            email: email,
            password: password
        }
        setLoading(true);
        DataServices.postData(postData, '/auth/signin').then((res) => {
            if (res.success) {
                sessionStorage.setItem('token', res.token);
                sessionStorage.setItem('user', JSON.stringify(res.user));
                props.history.push('/');
            } else {
                setError({...error, message: res.error});
                setLoading(false);
            }
        })

    };

    useEffect(() => {
        const infoFlag = props.match.params.flag;
        if (infoFlag) {
            switch (infoFlag) {
                case 'verified':
                    setInfo({
                        message: "Account verification successful. You can now start using your App",
                        type: "success"
                    });
                    break;
                case 'unverified':
                    setInfo({
                        message: "Account verification un-successful. Either already verified or link has expired. Kindly contact Admin",
                        type: "error"
                    });
                    break;
                case 'unlocked':
                    setInfo({
                        message: "Account unlocked successfully. You can now start using your App",
                        type: "success"
                    });
                    break;
                case 'locked':
                    setInfo({
                        message: "Account unlocking un-successful. Either already unlocked or link has expired. Kindly contact Admin",
                        type: "error"
                    });
                    break;
                case 'unsubscribed':
                    setInfo({message: "Successfully unsubscribed from our updates.", type: "success"});
                    break;
                case 'unsubscribe_error':
                    setInfo({
                        message: "Something went wrong while removing from mailing list. Kindly contact Admin",
                        type: "error"
                    });
                    break;
                default:
                    setInfo({});
                    break;
            }
        }
        const token = sessionStorage.getItem('token');
        if (token) {
            handleSignout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearError = () => {
        setError({});
    }
    const handleMasked = () => {
        setMask(!mask);
    }

    const handleSignout = () => {
        DataServices.postData({}, '/auth/logout').then((res) => {
            sessionStorage.clear();
        });
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.leftWrapper}>
                <div style={{textAlign:'center'}}>
                    <img className={classes.desktop} src={"/sample_app_screen.png"}/>
                    <div className={'two-column'}>
                        <div className={'half-wide'}>
                            <a href={'#'} target={"_blank"} rel={"noopener noreferrer"}><img className={classes.getApp} src={'/get_on_google_play.svg'} height={'60px'} alt={"Sensamind on Google Play"}/></a>
                        </div>
                        <div className={'half-wide'}>
                            <a href={'#'} target={"_blank"} rel={"noopener noreferrer"}><img className={classes.getApp} src={'/get_on_app_store.svg'} height={'60px'} alt={"Sensamind on Apple store"}/></a>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={8} md={5} className={classes.wrapper}>
                <div className={classes.paper}>
                    <div style={{textAlign:'center'}}>
                        <a href={'https://sensamind.com'} target={"_blank"} rel={"noopener noreferrer"}><img src={'/sensamind.png'} alt="Sensamind logo"/></a>
                    </div>
                    {info.message && <div className={`info-message ${info.type}`}>
                        <span>{info.message}</span>
                    </div>}
                    <form className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
                        <Typography className={classes.title}>Login</Typography>
                        <FormControl fullWidth className={classes.formField}>
                            <OutlinedInput
                                required
                                name="eid"
                                autoComplete="new-password"
                                placeholder="Username"
                                autoFocus
                                error={!!error.email}
                                onFocus={clearError}
                                startAdornment={<InputAdornment position="start">
                                    <UserIcon color={"primary"}/>
                                </InputAdornment>
                                }
                            />
                            {!!error.email && <FormHelperText>{error.email ? error.email : null}</FormHelperText>}
                        </FormControl>
                        <FormControl fullWidth className={classes.formField}>
                            <OutlinedInput
                                required
                                startAdornment={<InputAdornment position="start">
                                    <LockIcon color={"primary"}/>
                                </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        {!mask ? <PasswordVisible className={classes.eye} onClick={handleMasked}/> :
                                            <PasswordHide className={classes.eye} onClick={handleMasked}/>}
                                    </InputAdornment>
                                }
                                name="secret_phrase"
                                type={mask ? "password" : "text"}
                                placeholder={"Password"}
                                autoComplete="new-password"
                                onFocus={clearError}
                            />
                        </FormControl>
                        <div className={classes.secondaryAction}>
                                <span style={{cursor: 'pointer',}}
                                      onClick={() => props.history.push('/auth/forgot-password')}>
                                    Forgot password?
                                </span>
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            className={classes.submit}
                            disabled={loading}
                            variant={"contained"}
                            color={"primary"}
                            startIcon={loading && <CircularProgress size={15}/>}
                        >
                            Sign In
                        </Button>
                        <div style={{textAlign: "center", marginTop: '15px'}}>
                            <span style={{marginTop: '20px', color: 'red'}}>{error.message || null}</span>
                        </div>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}
import React, { Fragment, useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import moment from 'moment'
import { Card, Grid } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import ReactPlayer from 'react-player'

const useStyles = makeStyles(theme => ({
  paddingNull: {
    padding: '20px 0px',
    minHeight: 'calc(100vh - 70px)'
    // background: '#ffffff'
  },
  tableWrapper: {
    width: '100%'
  },
  evolWrapper: {
    width: '100%',
    margin: '2%'
  }
}))

const DetailsPage = props => {
  const classes = useStyles()
  const location = useLocation()

  return (
    <Fragment>
      <Container className={classes.paddingNull}>
        <div className={classes.tableWrapper}>
          <Typography
            component={'h2'}
            style={{ width: '50%' }}
            className={'title'}
          >
            Details Page
          </Typography>
        </div>
        <Grid
          container
          spacing={6}
          alignItems={'flex-start'}
          justifyContent={'space-around'}
        >
          {location.state?.data ? (
            <>
              <div style={{ padding: '20px 0 0 0' }}>
                <ReactPlayer
                  url={location.state?.data?.link}
                  playing={false}
                  loop={false}
                  controls={true}
                  light={true}
                />
                <Typography
                  className={'number'}
                  style={{
                    padding: '20px 0 0 0',
                    textTransform: 'capitalize',
                    lineHeight: '44px',
                    marginBottom: '20px'
                  }}
                >
                  {location.state?.data?.title}
                </Typography>
                <Typography variant={'subtitle2'} color='textPrimary'>
                  {location.state?.data?.description}
                </Typography>
                <p>
                  {moment(location.state?.data?.created_at).format(
                    'MM-DD-YYYY hh:mm A'
                  )}
                </p>
              </div>
            </>
          ) : (
            <>
              <Typography className={'number'}>
                Please select item first
              </Typography>
            </>
          )}
        </Grid>
      </Container>
    </Fragment>
  )
}

export default DetailsPage

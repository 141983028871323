import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    brandLogo:{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position:'absolute',
        top:'10px',
        [theme.breakpoints.down("sm")]:{
            justifyContent: "center",
            width:'100%',
            left:0,
            right:0,
            top:'20px',
            zIndex:'-33'
        }
    }
}));

function Header(props){
    const classes = useStyles();

    return (
        <div className={classes.brandLogo}>
            <img src={"/sensamind.png"} height={"75px"} alt="Sensamind"/>
        </div>
    )
}

export default Header;
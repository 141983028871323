import {createMuiTheme} from "@material-ui/core/styles";

const defaultTheme = createMuiTheme();
const MainTheme = createMuiTheme({
    palette: {
        primary:{
            main:"#7052fb",
        },
        secondary: {
            main: "#ffffff",
        },
    },
    typography:{
        fontFamily:[
            'Poppins',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            'Oxygen',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        subtitle2:{
            fontWeight:'bold'
        },
        subtitle1:{
            fontSize:'0.9rem'
        }
    },
    overrides: {
        MuiButton: {
            root : {
                textTransform: "none",
                fontWeight:400,
                minWidth: 100,
                fontSize:'1rem',
                borderRadius:"3px",
                boxShadow:'unset',
            },
            '&$active':{
                backgroundColor:"#7052fb",
                color:'#fff'
            }
        },
        MuiTableCell:{
            root:{
                padding:'15px',
                [defaultTheme.breakpoints.down("xs")]:{
                    padding: '20px'
                }
            },
            head:{
                fontWeight:'bold'
            }
        },
        MuiCardContent:{
            root:{
                '&:last-child':{
                    paddingBottom:'10px'
                }
            }
        },
        MuiIconButton:{
            root:{
                padding:'5px',
                textTransform:'capitalize'
            },
        },
        MuiBackdrop:{
            root:{
                backgroundColor: "rgba(255, 252, 252, 0.34)"
            }
        },
        MuiMenuItem:{
            root:{
                '&.Mui-selected':{
                    backgroundColor:"rgba(0, 0, 0, 0.15)"
                }
            }
        },
        MuiChip:{
            root:{
                backgroundColor:"#e0e0e073"
            },
            deleteIcon:{
                color:"rgba(0, 0, 0, 0.1)"
            }
        },
        MuiSvgIcon:{
            fontSizeSmall:{
                fontSize:'1rem'
            }
        },
        MuiSelect:{
            root:{
                fontSize:"14px"
            }
        },
        MuiTextField:{
            root:{
                marginBottom:'20px'
            }
        },
        MuiListItem:{
            root:{
                '&$Mui-selected':{
                    backgroundColor:'unset'
                }
            }
        },
        MuiListItemIcon:{
            root:{
                minWidth:'40px'
            }
        },
        MuiDrawer:{
            paperAnchorDockedLeft:{
                borderRight:'unset',
                boxShadow:'2px 0px 15px 0 #7052fb10'
            }
        },
        MuiContainer:{
            root:{
                [defaultTheme.breakpoints.down('sm')]:{
                    padding:'0 0 0 8px'
                }
            }
        }
    }
});

export default MainTheme;
import React, { useContext, useState } from 'react'
import ManageDataDialog from '../../../components/universal/dialog_component'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import DataServices from '../../../lib/services/api/data_services'
import { ToastMessageContext } from '../../../lib/contexts/message_context'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

function ManageUserGroups (props) {
  const { handleClose, action, currentSelection } = props
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState('member')
  const message = useContext(ToastMessageContext)

  const handleSubmit = formData => {
    if (action === 'create') {
      setLoading(true)
      formData['role'] = role
      DataServices.postData(formData, '/admin/user_groups').then(res => {
        if (res.success) {
          message.showToastMessage({
            message: 'User Group Added Successfully',
            variant: 'success'
          })
          setLoading(false)
          handleClose()
        } else {
          setLoading(false)
          message.showToastMessage({
            message: res.error ? res.error : 'Something went wrong. Try again',
            variant: 'error'
          })
        }
      })
    } else if (action === 'update') {
      formData['id'] = currentSelection.id
      setLoading(true)
      DataServices.updateData(formData, '/admin/user_groups').then(res => {
        if (res.success) {
          setLoading(false)
          message.showToastMessage({
            message: 'User Group Updated successfully',
            variant: 'success'
          })
          handleClose()
        } else {
          setLoading(false)
          message.showToastMessage({
            message: res.error ? res.error : 'Something went wrong. Try Again',
            variant: 'error'
          })
        }
      })
    }
  }

  return (
    <ManageDataDialog
      title={`${action} User Group`}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      loading={loading}
      type={action}
    >
      {action !== 'delete' ? (
        <Grid container spacing={4} alignItems={'center'} justify={'center'}>
          <Grid item xs={12}>
            <TextField
              name={'name'}
              label={'Group Name'}
              required
              fullWidth
              variant='outlined'
              defaultValue={currentSelection.name}
            />
            <TextField
              name={'description'}
              label={'Description'}
              required
              multiline
              rows={4}
              fullWidth
              variant='outlined'
              defaultValue={currentSelection.description}
            />
            <br />
            <br />
          </Grid>
        </Grid>
      ) : (
        <div>
          <Typography variant='subtitle2' gutterBottom>
            {' '}
            Confirm delete?
          </Typography>
          <Typography variant={'subtitle1'} gutterBottom>
            {' '}
            All Users within this group will be deleted and evolution labels
            will be removed.{' '}
          </Typography>
          <Typography variant={'subtitle1'} gutterBottom>
            {' '}
            This operation cannot be undone
          </Typography>
        </div>
      )}
    </ManageDataDialog>
  )
}

export default ManageUserGroups

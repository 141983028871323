import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  msgWrapper: {
    borderRadius: theme.spacing(1),
    background: "url('/background.png')",
    backgroundPosition: 'center',
    // background:'linear-gradient(100deg, rgba(250, 112, 112, 0.3) 0%, rgba(250, 151, 112, 0.2) 100%)',
    minHeight: '100px',
    padding: '3%',
    '& h1': {
      fontSize: '3.5em',
      fontWeight: '600',
      color: '#fff',
      [theme.breakpoints.down('md')]: {
        fontSize: '2em'
      }
    },
    '& .title': {
      textTransform: 'capitalize'
    },
    '& h4': {
      color: '#fff'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '50px'
    }
  }
}))

function UserGreetingMessage (props) {
  const classes = useStyles()
  const { user, overall } = props

  return !overall ? (
    <div className={classes.msgWrapper}>
      <Typography align={'center'} component={'h1'} className={'title'}>
        Welcome <span>{user.name}</span>
      </Typography>
      <Typography align={'center'} component={'h4'} variant={'subtitle2'}>
        Here's what happened in couple of days
      </Typography>
    </div>
  ) : (
    <div className={classes.msgWrapper}>
      <Typography align={'center'} component={'h1'} className={'title'}>
        {user.name + "'s"} Dashboard
      </Typography>
      <Typography align={'center'} component={'h4'} variant={'subtitle2'}>
        Collated Collection of User Click data
      </Typography>
    </div>
  )
}

export default UserGreetingMessage

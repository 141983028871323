import React from "react";
import Divider from "@material-ui/core/Divider";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SendIcon from "@material-ui/icons/Send";

import Button from "@material-ui/core/Button";

import "./message.scss";
import NoImage from './../Message/no_image.jpg'

const ErrorImage =(e)=>{
 e.target.src = NoImage;
}

const SenderSms = (props) => {
  const clickDocs = (d) => {
    const newWindow = window.open(d, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div className="sender-block">
      <p>{props.data.message}</p>
      {props.data.attachment && (
        <span onClick={() => clickDocs(props.data.attachment)}>
          
          <img src={props.data.attachment} alt='' onError={(e)=>ErrorImage(e)} className='sms_image' />
        </span>
      )}
    </div>
  );
};

const RecieverSms = (props) => {
  const clickDocs = (d) => {
    const newWindow = window.open(d, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div className="reciver-block">
      <p>{props.data.message}</p>
      {props.data.attachment && (
        <span onClick={() => clickDocs(props.data.attachment)}>
          <img src={props.data.attachment} alt='' onError={(e)=>ErrorImage(e)} className='sms_image' />
        </span>
      )}
    </div>
  );
};
const RightSide = (props) => {
  let smsData = props.smsList?.length !== 0 ? Object.values(props.smsList) : [];
  return (
    <div className="message-right">
      <div className="right-top">
        <div className="user-list">
          <div className="img-block">
            {props.userDetails ? (
              <span className="profile_name">
                {props.userDetails?.name[0] ?? "-"}
              </span>
            ) : (
              ""
            )}
          </div>
          {props.userDetails ? <p>{props.userDetails.name}</p> : <p>...</p>}
        </div>
      </div>
      <div className="bodySms-show">
        {smsData?.length !== 0 ? (
          <>
            {smsData?.map((item, index) => {
              if (item?.receiver?.id == props.userDetails?.id) {
                return (
                  <React.Fragment key={index}>
                    <RecieverSms data={item} />
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    <SenderSms data={item} />
                  </React.Fragment>
                );
              }
            })}
          </>
        ) : (
          <>No Data found</>
        )}
      </div>
      <Divider />
      <form onSubmit={props.messageSend}>
        <div className="right-bottom">
          <AccountCircleIcon />
          <div className="search-block padding-10">
            <input
              name="send"
              placeholder="Send message..."
              className="msg"
              required
            />

            <div className="file_upload">
              {props.fileState &&<div className="show_file_name">
                <label>{props.fileState?.name}</label>
                <span onClick={props.hideFileClick}>x</span>
              </div>}
              <input type="file" name="file_upload" className="fileupload" onChange={props.fileSelect} />
            </div>
          </div>
          <Button variant="outlined" type="submit">
            <SendIcon />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RightSide;

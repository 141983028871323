import React from 'react';
import MainTheme from "./theme/main_theme";
import {Dots} from "react-preloaders";
import {BrowserRouter as Router} from 'react-router-dom';
import BrowserAppRoutes from "./views/Routes";
import {ThemeProvider} from '@material-ui/styles';
import ToastMessageProvider from "./lib/contexts/message_context";
import Container from "@material-ui/core/Container";
import './App.css';

function App() {
    return (
        <Router onUpdate={() => document.getElementById('main_content').scrollTo(10, 10)}>
            <Dots background={"#7052fb"} color={"#fff"}/>
            <ThemeProvider theme={MainTheme}>
                <ToastMessageProvider>
                    {/*<Container maxWidth={'xl'}>*/}
                        <BrowserAppRoutes/>
                    {/*</Container>*/}
                </ToastMessageProvider>
            </ThemeProvider>
        </Router>
    );
}

export default App;

import React, {useContext, useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import DataServices from "../../lib/services/api/data_services";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import FilterIcon from "@material-ui/icons/FilterList";

const useStyles = makeStyles(theme => ({
    chips: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}))

function FilterData(props) {

    const classes = useStyles();
    const {handleFilters, resetFilter, updateFilter, filters, role, refreshData} = props;
    const [userGroups, setUserGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const message = useContext(ToastMessageContext);

    useEffect(() => {
        if (role === 'super_admin') {
            setLoading(true);
            const params = {};
            DataServices.getData(params, '/admin/user_groups').then((res) => {
                const groups = res.user_groups;
                let tmpGroups = groups.map(g => {
                    return ({id: g.id, label: g.name, value: g.id})
                });
                setUserGroups(tmpGroups);
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                message.showToastMessage({
                    message: "something went wrong with filters. Try again later",
                    variant: 'error'
                });
            });
        }

    }, [role]);

    const handleSubmit = () => {
        refreshData();
    }

    const evolutions = [
        {value: 0, label: "All Evolutions"},
        {value: 1, label: "Evolution 1"},
        {value: 2, label: "Evolution 2"},
        {value: 3, label: "Evolution 3"},
        {value: 4, label: "Evolution 4"},

    ];

    const ageOptions = [
        {value: "100-100", label: "All Age"},
        {value: "10-19", label: "10-19"},
        {value: "20-35", label: "20-35"},
        {value: "36-50", label: "36-50"},
        {value: "51-70", label: "51-70"},
    ];

    const timeRangeOptions = [
        {value: '0', label: "All Time"},
        {value: '1', label: "1hr"},
        {value: '6', label: "6hr"},
        {value: '12', label: "12hr"},
        {value: '24', label: "24hr"},
        {value: '72', label: "72hr"},
    ];

    const genderOptions = [
        {value: 10, label: "All Genders"},
        {value: 0, label: "Female"},
        {value: 1, label: "Male"},
        {value: 2, label: "Others"},
    ]

    return (
        <Grid container spacing={3} alignItems={"center"} justify={"space-between"}>
            <Grid item xs={6} md={3}>
                <FormControl fullWidth variant={"outlined"} className={"field"}>
                    <InputLabel id="evolution-label">User Group</InputLabel>
                    <Select
                        labelId={"User Group"}
                        label={"User Group"}
                        name='user_group'
                        value={filters.user_group}
                        onChange={handleFilters}
                        placeholder={"User Group"}
                        disabled={role === 'admin' || loading}
                        style={{textTransform: 'capitalize'}}
                    >
                        {userGroups.map((grp, i) => (
                            <MenuItem key={`grp-${i}`} value={grp.value}
                                      style={{textTransform: 'capitalize'}}>{grp.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
                <FormControl fullWidth variant={"outlined"} className={"field"}>
                    <InputLabel id="evolution-label">Evolution</InputLabel>
                    <Select
                        labelId={"Evolution"}
                        label={"Evolution"}
                        name='evolution'
                        value={filters.evolution}
                        onChange={handleFilters}
                        placeholder={"Evolution"}
                        disabled={loading}
                    >
                        {evolutions.map((evol, i) => (
                            <MenuItem key={i} value={evol.value}>{evol.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
                <FormControl fullWidth variant={"outlined"} className={"field"}>
                    <InputLabel id="age-label">Age</InputLabel>
                    <Select
                        labelId={"age-label"}
                        label={"Age"}
                        name='age'
                        value={filters.age}
                        onChange={handleFilters}
                        placeholder={"Age"}
                        disabled={loading}
                    >
                        {ageOptions.map((age, i) => (
                            <MenuItem key={i} value={age.value}>{age.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
                <FormControl fullWidth variant={"outlined"} className={"field"}>
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                        labelId={"gender-label"}
                        label={"Gender"}
                        name='gender'
                        value={filters.gender}
                        onChange={handleFilters}
                        placeholder={"Gender"}
                        disabled={loading}
                    >
                        <MenuItem value={10}>All</MenuItem>
                        <MenuItem value={0}>Female</MenuItem>
                        <MenuItem value={1}>Male</MenuItem>
                        <MenuItem value={2}>Other</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="zipcode-label">Zipcode</InputLabel>
                    <OutlinedInput label={"Zipcode"} value={filters.zipcode} placeholder={"Zipcode"}
                                   onChange={handleFilters}
                                   name={"zipcode"}/>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Time Range</FormLabel>
                    <RadioGroup row={true} aria-label="time_range" name="time_range" value={filters.time_range}
                                onChange={handleFilters}>
                        {timeRangeOptions.map((time, i) => (
                            <FormControlLabel key={i} value={time.value} control={<Radio color={"primary"}/>}
                                              label={time.label} selected={filters.time_range === time.value}/>
                        ))}
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="subtitle2">Applied Filters:</Typography>
                <div className={classes.chips}>
                    {filters['user_group'] !== 0 && <Chip
                        label={`User group : ${userGroups.find(grp => grp.value === filters['user_group'])?.label}`}
                        onDelete={(e) => updateFilter({...filters, 'user_group': 0})}/>}
                    {filters['evolution'] !== 0 && <Chip
                        label={`Evolution : ${evolutions.find(evol => evol.value === filters['evolution'])?.label}`}
                        onDelete={(e) => updateFilter({...filters, 'evolution': 0})}/>}
                    {filters['age'] !== 0 && <Chip
                        label={`Age : ${ageOptions.find(ag => ag.value === filters['age'])?.label}`}
                        onDelete={(e) => updateFilter({...filters, 'age': 0})}/>}
                    {filters['gender'] !== 0 && <Chip
                        label={`Gender : ${genderOptions.find(gen => gen.value === filters['gender'])?.label}`}
                        onDelete={(e) => updateFilter({...filters, 'gender': 10})}/>}
                    {filters['time_range'] !== 0 && <Chip
                        label={`Time Range : ${timeRangeOptions.find(time => time.value === filters['time_range'])?.label}`}
                        onDelete={(e) => updateFilter({...filters, 'time_range': 0})}/>}
                    {filters['zipcode'] && <Chip
                        label={`Zipcode : ${filters['zipcode']}`}
                        onDelete={(e) => updateFilter({...filters, 'zipcode': ''})}/>}
                </div>
            </Grid>
            <Grid item xs={12} md={2}>
                <Button color={"primary"} onClick={resetFilter} style={{marginRight: '15px'}}>Reset filters</Button>
            </Grid>
            <Grid item xs={12} md={10}/>
            <Grid item xs={12} md={2}>
                <Button color={"primary"} variant={"contained"} onClick={handleSubmit}
                        style={{marginRight: '15px'}} startIcon={<FilterIcon/>}>Apply Filters</Button>
            </Grid>
        </Grid>

    )
}

export default FilterData;
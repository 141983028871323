import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CountUp from 'react-countup'
import moment from 'moment'

function CumulativeStats (props) {
  const { stats, todayStats, bluetoothClicks, overAllStats } = props
  const [loading, setLoading] = useState(false)
  const [currentStat, setCurrentStat] = useState({
    percentageChange: 0,
    totalClicksYesterday: 0,
    totalClicksToday: 0,
    overallClicks: 0,
    averageClicks: 0
  })

  useEffect(() => {
    setLoading(true)
    let newCurrentStat = { ...currentStat }
    let ytotalSum = 0
    let totalSum = 0
    if (stats.yesterday && stats.yesterday.button_clicks) {
      ytotalSum = stats.yesterday.total
      newCurrentStat = { ...newCurrentStat, totalClicksYesterday: ytotalSum }
    }
    if (stats.today) {
      totalSum = stats.today.total
      const percentageChange =
        +ytotalSum > 0 ? (+(+totalSum - +ytotalSum) / +ytotalSum) * 100 : 0
      setCurrentStat({
        ...newCurrentStat,
        totalClicksToday: totalSum,
        percentageChange: percentageChange
      })
    }
    if (overAllStats) {
      let overallTotal = overAllStats.total || 0
      let avgClicks = 0
      let dayDiff = 1
      dayDiff = moment().diff(moment(overAllStats.first_click), 'days')
      if (dayDiff > 0) {
        avgClicks = overallTotal / (dayDiff + 1)
      }
      setCurrentStat({
        ...newCurrentStat,
        overallClicks: overallTotal,
        averageClicks: avgClicks
      })
    }
  }, [overAllStats.total])

  return (
    <Grid
      container
      spacing={6}
      alignItems={'flex-start'}
      justify={'space-around'}
      style={{ paddingBottom: '3%' }}
    >
      <Grid item md={3} xs={6}>
        <Card elevation={0} className={'card'} style={{ paddingBottom: 0 }}>
          <Typography
            style={{ height: '42px' }}
            variant={'subtitle2'}
            color='primary'
          >
            Total Clicks
          </Typography>
          <Typography className={'number'}>
            <CountUp end={currentStat.overallClicks} delay={1} />
          </Typography>
        </Card>
      </Grid>
      {/* <Grid item md={3} xs={6}>
        <Card elevation={0} className={'card'} style={{ paddingBottom: 0 }}>
          <Typography
            style={{ height: '42px' }}
            variant={'subtitle2'}
            color='primary'
          >
            Total Bluetooth Clicks
          </Typography>
          <Typography className={'number'}>
            <CountUp end={bluetoothClicks} delay={1} />
          </Typography>
        </Card>
      </Grid> */}
      <Grid item md={3} xs={6}>
        <Card elevation={0} className={'card'} style={{ paddingBottom: 0 }}>
          <Typography
            style={{ height: '42px' }}
            variant={'subtitle2'}
            color='primary'
          >
            Average Clicks per day
          </Typography>
          <Typography className={'number'}>
            <CountUp
              decimals={1}
              decimal={'.'}
              end={currentStat.averageClicks}
              delay={1}
            />
          </Typography>
        </Card>
      </Grid>
      <Grid item md={3} xs={6}>
        <Card elevation={0} className={'card'} style={{ paddingBottom: 0 }}>
          <Typography
            style={{ height: '42px' }}
            variant={'subtitle2'}
            color='primary'
          >
            Percentage Change (Y vs T)
          </Typography>
          <Typography className={'number'}>
            <span className={'positive-text'}>
              {currentStat.percentageChange >= 0 ? '+' : null}
            </span>
            <span
              className={
                currentStat.percentageChange >= 0
                  ? 'positive-text'
                  : 'negative-text'
              }
            >
              <CountUp end={currentStat.percentageChange} delay={1} />%
            </span>
          </Typography>
        </Card>
      </Grid>
    </Grid>
  )
}

export default CumulativeStats

import React, { useEffect, useState } from 'react'
import tableIcons, {
  options
} from '../../components/universal/table_attributes'
import MaterialTable from 'material-table'
import Button from '@material-ui/core/Button'
import DataServices from '../../lib/services/api/data_services'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import './AllUser.css'

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    width: '95%'
  },
  subtitle: {
    fontSize: '1.2rem',
    textTransform: 'capitalize',
    padding: '25px 0'
  },
  divClass: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    width: 'calc(100% - 40px)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    gap: '20px'
  }
}))

function ClicksRawData (props) {
  const classes = useStyles()
  const { filters, user, refresh, evaluationKey } = props
  let _isMounted = false
  const [loading, setLoading] = useState(false)
  const [clicks, setClicks] = useState([])

  useEffect(() => {
    _isMounted = true
    const userId = props.userId
    let params = {}
    if (user.role !== 'user') {
      params = {
        id: userId,
        evolution: props.evaluationKey,
        ...filters
      }
    }
    setLoading(true)
    DataServices.getData(params, '/user/clicks?evolution=' + evaluationKey)
      .then(res => {
        if (res.success && _isMounted) {
          setClicks(res.clicks)
        }
      })
      .finally(() => {
        _isMounted && setLoading(false)
      })

    return () => {
      _isMounted = false
    }
  }, [props.userId, refresh, evaluationKey])

  const tableOptions = {
    ...options,
    search: false
  }

  const columns = [
    {
      title: 'Button',
      field: 'button'
    },
    {
      title: 'Cause',
      field: 'cause'
    },
    {
      title: 'Additional Desc',
      field: 'additional_info',
      emptyValue: 'N/A',
      render: rowData => <span>{rowData.additional_info || 'NA'}</span>
    },
    {
      title: 'Clicked At',
      field: 'clicked_at',
      defaultSort: 'desc',
      render: rowData => (
        <span>
          {moment.utc(rowData.clicked_at).local().format('MM/DD/YYYY hh:mm A')}
        </span>
      )
    }
  ]

  if (evaluationKey === 1) {
    return (
      <>
        <div className={classes.divClass}>
          <Typography variant={'subtitle2'} color='primary'>
            All Time Clicks
          </Typography>

          <div className='custom_table'>
            <div className='custom_header'>
              <p className='p-block custom_fist_p'>Input</p>
              <p className='p-block created_block'>Created</p>
            </div>
            {clicks.map((item, index) => {
              return (
                <div className='custom_row' key={index}>
                  <p className='p-block custom_fist_p one_line_text'>
                    {item?.input ?? '-'}
                  </p>
                  <p className='p-block created_block one_line_text'>
                    {moment(item?.created_at).format('LLL') ?? '-'}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }

  return (
    <div style={{ width: '90%', margin: 'auto' }}>
      <MaterialTable
        style={{ boxShadow: 'unset' }}
        icons={tableIcons}
        title={
          <Typography variant={'subtitle2'} color='primary'>
            All Time Clicks
          </Typography>
        }
        columns={columns}
        data={clicks || []}
        isLoading={loading}
        options={tableOptions}
        localization={{
          body: {
            emptyDataSourceMessage: 'No Clicks found',
            filterRow: {
              filterTooltip: 'Filter'
            }
          }
        }}
      />
    </div>
  )
}

export default ClicksRawData

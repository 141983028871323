import {makeStyles} from "@material-ui/core/styles";

const useAuthStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        width:'80%',
        margin:'auto',
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'90vh',
            justifyContent:'center'
        }
    },
    wrapper: {
        height: '100%',
        width:'90%',
        margin:'auto',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        },
        '& .grid':{
            height:'95vh',
            [theme.breakpoints.down('sm')]: {
                alignContent: 'center',
                height:'100vh'
            }
        },
        '& .section-holder-image':{
            [theme.breakpoints.down('sm')]:{
                display:'none'
            },
        }
    },
    leftWrapper:{
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1.2em',
        color: theme.palette.common.black,
        padding:'5% 0',
    },
    orgLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            maxWidth: '200px',
            maxHeight: '80px'
        }
    },
    form: {
        padding:'0 10%',
        '& h6': {
            marginBottom: '20px'
        },
        [theme.breakpoints.down("sm")]: {
            padding:0,
            width: '85%',
            margin: 'auto',
            zIndex:'9999',
            backgroundColor:'#fafafa'
        }
    },
    formField: {
        marginBottom: '20px',
    },
    secondaryAction:{
        color:theme.palette.grey['600'],
        margin: '5px 0 20px 0',
        textAlign:"right",
        '&:hover':{
            color:theme.palette.primary.main
        }
    },
    submit: {
        borderRadius: '5px',
        margin:'auto',
        width:'100%'
    },
    eye: {
        cursor: "pointer",
        color: theme.palette.grey['400'],
        '&:hover':{
            color:theme.palette.primary.main
        }
    },
    desktop: {
        [theme.breakpoints.down('sm')]: {
            display:'none'
        }
    },
    getApp: {
        [theme.breakpoints.down('sm')]: {
            height:'50px !important'
        }
    }
}));

export default useAuthStyles;
import React, {useContext, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import PasswordVisible from "@material-ui/icons/Visibility";
import PasswordHide from "@material-ui/icons/VisibilityOff";
import LockIcon from "@material-ui/icons/LockOutlined";
import useAuthStyles from "./auth_styles";
import DataServices from "../../lib/services/api/data_services";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import FormHelperText from "@material-ui/core/FormHelperText";
import Header from "../../components/universal/header";
import UserIcon from "@material-ui/icons/PersonOutlined";
import {validateEmail} from "../../lib/utils/common_utils";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";


export default function ResetPassword(props) {
    const classes = useAuthStyles();
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [mask, setMask] = useState(true);
    const message = useContext(ToastMessageContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const password = formData.get('secret_phrase');
        const passwordConfirmation = formData.get('secret_phrase_confirm');
        const email = formData.get('eid');

        if (!validateEmail(email.trim())) {
            setError({...error,email:true});
            return;
        }

        if (password !== passwordConfirmation) {
            setError({...error,password:true});
            return;
        }

        const postData = {
            email:email,
            password: password,
            password_confirmation: passwordConfirmation,
            token: props.match.params.token
        }
        setLoading(true);
        DataServices.postData(postData,'/auth/reset-password').then((res)=>{
            if(res.success){
                message.showToastMessage({message:"Password changed successfully. Login using new password",variant:"success"});
                props.history.replace('/auth/signin');
            }
            else {
                setError({...error,message:res.error});
            }
            setLoading(false);
        })

    };

    useEffect(() => {
        const token = props.match.params.token;
        if(!token){
            props.history.push('/auth/signin');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearError = () => {
        setError({});
    }
    const handleMasked = () => {
        setMask(!mask);
    }
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.leftWrapper}>
                <div style={{textAlign:'center'}}>
                    <img className={classes.desktop} src={"/sample_app_screen.png"}/>
                    <div className={'two-column'}>
                        <div className={'half-wide'}>
                            <a href={'#'} target={"_blank"} rel={"noopener noreferrer"}><img className={classes.getApp} src={'/get_on_google_play.svg'} height={'60px'} alt={"Sensamind on Google Play"}/></a>
                        </div>
                        <div className={'half-wide'}>
                            <a href={'#'} target={"_blank"} rel={"noopener noreferrer"}><img className={classes.getApp} src={'/get_on_app_store.svg'} height={'60px'} alt={"Sensamind on Apple store"}/></a>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={8} md={5} className={classes.wrapper}>
                <div className={classes.paper}>
                    <div style={{textAlign:'center'}}>
                        <a href={'https://sensamind.com'} target={"_blank"} rel={"noopener noreferrer"}><img src={'/sensamind.png'} alt="Sensamind logo"/></a>
                    </div>
                    <form className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
                        <Typography className={classes.title}>Reset Password</Typography>
                        <FormControl fullWidth className={classes.formField}>
                            <OutlinedInput
                                required
                                name="eid"
                                autoComplete="new-password"
                                placeholder="Username"
                                autoFocus
                                error={!!error.email}
                                onFocus={clearError}
                                startAdornment={<InputAdornment position="start">
                                    <UserIcon color={"primary"}/>
                                </InputAdornment>
                                }
                            />
                            {!!error.email && <FormHelperText>{error.email ? error.email : null}</FormHelperText>}
                        </FormControl>
                        <FormControl fullWidth className={classes.formField}>
                            <OutlinedInput
                                required
                                startAdornment={<InputAdornment position="start">
                                    <LockIcon color={"primary"}/>
                                </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        {!mask ? <PasswordVisible className={classes.eye} onClick={handleMasked}/> :
                                            <PasswordHide className={classes.eye} onClick={handleMasked}/>}
                                    </InputAdornment>
                                }
                                name="secret_phrase"
                                type={mask ? "password" : "text"}
                                placeholder={"Password"}
                                autoComplete="new-password"
                                onFocus={clearError}
                            />
                        </FormControl>
                        <FormControl fullWidth className={classes.formField}>
                            <OutlinedInput
                                required
                                startAdornment={<InputAdornment position="start">
                                    <LockIcon color={"primary"}/>
                                </InputAdornment>
                                }
                                name="secret_phrase_confirm"
                                type={mask ? "password" : "text"}
                                placeholder={"Re-enter Password"}
                                autoComplete="new-password"
                                onFocus={clearError}
                            />
                            {!!error.password && <FormHelperText error={true}>{error.password ? "Passwords does not match!. Try again" : null}</FormHelperText>}
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            className={classes.submit}
                            disabled={loading}
                            variant={"contained"}
                            color={"primary"}
                            startIcon={loading && <CircularProgress size={15}/>}
                        >
                            Reset Password
                        </Button>
                        <div style={{textAlign: "center", marginTop: '15px'}}>
                            <span style={{marginTop: '20px', color: 'red'}}>{error.message || null}</span>
                        </div>
                    </form>
                </div>
            </Grid>
        </Grid>

    );
}
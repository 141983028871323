import React, {useEffect, useState} from "react";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import PieChart from 'react-apexcharts';
import {flattenArrayOfObjectsToArray} from "../../lib/utils/common_utils";


function CauseClickStats(props){

    const {stats} = props;
    const [causeStat,setCauseStat] = useState({
        cause_counts:[],
        cause_labels:[]
    });

    useEffect(()=>{
        let newStats = {...causeStat};
        const clicks = stats.cause_clicks;
        if(clicks && clicks.length >0){
            newStats = {
                ...newStats,
                cause_labels: flattenArrayOfObjectsToArray(clicks,'cause'),
                cause_counts: flattenArrayOfObjectsToArray(clicks, 'total',true)
            }
        }
        setCauseStat(newStats);
    },[stats.cause_clicks])

    return (
        <Grid container spacing={4} alignItems={"flex-start"} justify={"center"}>
            <Grid item md={12} xs={12}>
                <Typography style={{padding:'10px 25px',fontSize:'1em',color:'#000'}} variant={"subtitle2"}>Causes Clicks</Typography>
            </Grid>
            {stats && stats.cause_clicks && stats.cause_clicks.length > 0 ?<PieChart
                options={{
                    labels:causeStat.cause_labels || [],
                    legend:{
                        position:'bottom'
                    },
                }}
                series={causeStat.cause_counts || []}
                type="donut"
                width="400"
            />
            :<div style={{height:'300px',display:'flex',alignItems:'center'}}><Typography> No clicks made yet</Typography></div>}
        </Grid>
    )
}

export default CauseClickStats;


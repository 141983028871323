export const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const convertFormDataToJSON = (formData) => {
    var object = {};
    formData.forEach(function(value, key){
        object[key] = value;
    });
    var json = JSON.stringify(object);
    return object;
}

export const flattenArrayOfObjectsToArray = (object,key,flag=null) =>{
    let values = [];
    for (let i =0; i < object.length; i++){
        if(object[i].hasOwnProperty(key)){
            if(flag){
                values.push(Number(object[i][key]));
            }
            else {
                values.push(object[i][key]);
            }
        }
    }
    return values;
};

import React, {useContext, useEffect, useState} from "react";
import DataServices from "../../lib/services/api/data_services";
import {ToastMessageContext} from "../../lib/contexts/message_context";

function VerifyAccount(props) {

    const message = useContext(ToastMessageContext);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        setLoading(true);
        const token = props.match.params.token;
        DataServices.postData({verification_code:token},'/auth/verify-account').then((res)=>{
            if(res.success){
                setLoading(false);
                props.history.push('/auth/signin/verified');
            }
            else {
                setLoading(false);
                props.history.push('/auth/signin/unverified');
                message.showToastMessage({message:res.error?res.error:"Something went wrong. Try again",variant:'error'});
            }
        })
    },[]);

    return null;
}

export default VerifyAccount;
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
    wrapper:{
        padding:'5%'
    },
    imageWrapper:{
        textAlign:'center',
        [theme.breakpoints.down('sm')]:{
            margin:'auto',
            '& img':{
                width:'90%'
            }
        }
    },
    title: {
        fontWeight: 'bold',
    },
    subheader:{
        fontSize:'20px',
        color:theme.palette.primary.light
    }
}));

function PageNotFound() {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <div className={classes.imageWrapper}>
                <img src={'/404.png'}/>
            </div>
            <div style={{width: '100%',textAlign:'center'}}>
                <Typography variant='h2' color="primary" className={classes.title} gutterBottom>
                    Oops!
                </Typography>
                <Typography className={classes.subheader} gutterBottom>
                    The page you are looking for does not exist.<br/>
                    You might have mistyped the address. Please check the URL and try again.
                </Typography>
            </div>
        </div>
    )
}

export default PageNotFound;
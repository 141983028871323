import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { requireAuth } from '../Auth/hoc/require_auth'
import SignIn from '../Auth/signin'
import ForgotPassword from '../Auth/forgot_password_request'
import Footer from '../../components/universal/footer'
import ResetPassword from '../Auth/reset_password'
import ToastMessage from '../../components/universal/status_message'
import PageNotFound from '../../components/universal/page_not_found'
import UserListing from '../Admin/UserGroup/user_listing'
import LabelListing from '../Admin/label_listing'
import UserDetails from '../User'
import VerifyAccount from '../Auth/verify_account'
import UnlockAccount from '../Auth/unlock_account'
import UserGroupsList from '../Admin/UserGroup'
import MessageView from '../Admin/Message/message'
import AdminBlogList from '../Blog/AdminBlogList'
import AdminBlogCreate from '../Blog/Create'
import AdminBlogEdit from '../Blog/Edit'
import UserBlog from '../Blog/UserBlogList'

import TutorialAdmin from '../Blog/Tutorial/AdminList'
import TutorialUser from '../Blog/Tutorial/UserList'
import TutorialEdit from '../Blog/Tutorial/Edit'
import TutorialCreate from '../Blog/Tutorial/Create'

import ForumAdmin from '../Blog/Forum/AdminList'
import ForumUser from '../Blog/Forum/UserList'
import ForumEdit from '../Blog/Forum/Edit'
import ForumCreate from '../Blog/Forum/Create'

import DetailsPage from '../Blog/DetailsPage'

export default function BrowserAppRoutes () {
  const SUPER_ADMIN = requireAuth(['super_admin'])
  const ADMIN = requireAuth(['admin', 'super_admin'])
  const USER = requireAuth(['user', 'admin', 'super_admin'])

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path={'/message'} component={USER(MessageView)} />
          <Route exact path={'/'} component={USER(UserDetails)} />
          <Route
            exact
            path={'/auth/forgot-password'}
            component={ForgotPassword}
          />
          <Route
            exact
            path={'/auth/change-password/:session'}
            component={ForgotPassword}
          />
          <Route
            exact
            path={'/auth/reset-password/:token'}
            component={ResetPassword}
          />
          <Route
            exact
            path={'/auth/verify-account/:token'}
            component={VerifyAccount}
          />
          <Route
            exact
            path={'/auth/unlock-account/:token'}
            component={UnlockAccount}
          />
          <Route
            exact
            path={'/mailing_list/unsubscribe/:token'}
            component={UnlockAccount}
          />
          <Route exact path={'/auth/signin'} component={SignIn} />
          <Route exact path={'/auth/signin/:flag'} component={SignIn} />
          <Route
            exact
            path={'/users/:groupId'}
            component={ADMIN(UserListing)}
          />
          <Route
            exact
            path={'/users/sensamind/:role'}
            component={SUPER_ADMIN(UserListing)}
          />
          <Route
            exact
            path={'/user_groups/'}
            component={SUPER_ADMIN(UserGroupsList)}
          />
          <Route
            exact
            path={'/users/:userId/details'}
            component={ADMIN(UserDetails)}
          />
          {/* blog */}
          <Route exact path={'/user/blog'} component={USER(UserBlog)} />
          <Route
            exact
            path={'/admin/blog/list'}
            component={SUPER_ADMIN(AdminBlogList)}
          />
          <Route
            exact
            path={'/admin/blog/create'}
            component={SUPER_ADMIN(AdminBlogCreate)}
          />
          <Route
            exact
            path={'/admin/blog/edit/:id'}
            component={SUPER_ADMIN(AdminBlogEdit)}
          />
          {/* tutorial */}
          <Route exact path={'/user/tutorial'} component={USER(TutorialUser)} />
          <Route
            exact
            path={'/admin/tutorial/list'}
            component={SUPER_ADMIN(TutorialAdmin)}
          />
          <Route
            exact
            path={'/admin/tutorial/create'}
            component={SUPER_ADMIN(TutorialCreate)}
          />
          <Route
            exact
            path={'/admin/tutorial/edit/:id'}
            component={SUPER_ADMIN(TutorialEdit)}
          />
          {/* forum */}
          <Route exact path={'/user/forum'} component={USER(ForumUser)} />
          <Route
            exact
            path={'/admin/forum/list'}
            component={SUPER_ADMIN(ForumAdmin)}
          />
          <Route
            exact
            path={'/admin/forum/create'}
            component={SUPER_ADMIN(ForumCreate)}
          />
          <Route
            exact
            path={'/admin/forum/edit/:id'}
            component={SUPER_ADMIN(ForumEdit)}
          />
          {/* deatails */}
          <Route exact path={'/details/:type'} component={USER(DetailsPage)} />
          {/* end */}
          <Route path={'/auth/'} component={SignIn} />
          <Route component={PageNotFound} />
        </Switch>
        <ToastMessage />
      </div>
      <Footer />
    </Router>
  )
}

import React, { useContext, useState } from 'react'
import ManageDataDialog from '../../components/universal/dialog_component'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import DataServices from '../../lib/services/api/data_services'
import { ToastMessageContext } from '../../lib/contexts/message_context'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

function ManageUsers (props) {
  const { handleClose, action, currentSelection, role } = props
  const [loading, setLoading] = useState(false)
  const [userRole, setRole] = useState(role ? 'super_admin' : 'user')
  const message = useContext(ToastMessageContext)
  const currentUser = JSON.parse(sessionStorage.getItem('user'))
  const currentUserRole = currentUser.role

  const handleSubmit = formData => {
    if (action === 'create') {
      setLoading(true)
      formData['role'] = userRole
      formData['user_group'] = props.groupId || currentUser.user_group.id
      DataServices.postData(formData, '/admin/users').then(res => {
        if (res.success) {
          message.showToastMessage({
            message: 'User Added Successfully',
            variant: 'success'
          })
          setLoading(false)
          handleClose()
        } else {
          setLoading(false)
          message.showToastMessage({
            message: res.error ? res.error : 'Something went wrong. Try again',
            variant: 'error'
          })
        }
      })
    } else if (action === 'update') {
      formData['id'] = currentSelection.id
      formData['role'] = userRole
      setLoading(true)
      DataServices.updateData(formData, '/user').then(res => {
        if (res.success) {
          setLoading(false)
          message.showToastMessage({
            message: 'User Updated successfully',
            variant: 'success'
          })
          handleClose()
        } else {
          setLoading(false)
          message.showToastMessage({
            message: res.error ? res.error : 'Something went wrong. Try Again',
            variant: 'error'
          })
        }
      })
    }
  }
  return (
    <ManageDataDialog
      title={`${action} ${role ? 'Super Admin' : ''} User Details`}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      loading={loading}
      type={action}
    >
      {action !== 'delete' ? (
        <Grid container spacing={4} alignItems={'center'} justify={'center'}>
          <Grid item xs={12}>
            <TextField
              name={'name'}
              label={'Name'}
              required
              fullWidth
              variant='outlined'
              defaultValue={currentSelection.name}
            />
            <TextField
              name={'email'}
              label={'User Email'}
              required
              fullWidth
              variant='outlined'
              type='email'
              inputProps={{ readOnly: action === 'update' }}
              defaultValue={currentSelection.email}
            />
            <TextField
              name={'age'}
              label={'Age'}
              required
              fullWidth
              variant='outlined'
              type='number'
              inputProps={{ min: 1, max: 130, step: 1 }}
              defaultValue={currentSelection.age}
            />
            <FormControl fullWidth>
              <NativeSelect
                name='gender'
                inputProps={{ 'aria-label': 'gender' }}
              >
                <option value={0}>Female</option>
                <option value={1}>Male</option>
                <option value={2}>Other</option>
              </NativeSelect>
            </FormControl>
            <br />
            <br />
            {!role && (
              <RadioGroup
                aria-label='role'
                name='role'
                value={userRole}
                onChange={e => setRole(e.target.value)}
              >
                <FormControlLabel
                  value='user'
                  control={<Radio color={'primary'} />}
                  label='General User'
                />
                <FormControlLabel
                  value='admin'
                  control={<Radio color={'primary'} />}
                  label='Group Admin'
                />
                {currentUserRole === 'super_admin' && !props.groupId && (
                  <FormControlLabel
                    value='super_admin'
                    control={<Radio color={'primary'} />}
                    label='Sensamind Admin'
                  />
                )}
              </RadioGroup>
            )}
            <br />
            <br />
            <TextField
              name={'zipcode'}
              label={'Zipcode'}
              required
              fullWidth
              variant='outlined'
              defaultValue={currentSelection.zipcode}
            />
            <InputLabel id='evolution'>Evolution</InputLabel>
            <Select
              defaultValue={currentSelection.evolution}
              labelId='evolution'
              id='evolution'
              name='evolution'
              label={'evolution'}
              placeholder={'evolution'}
              fullWidth
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </Select>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='subtitle2'> Confirm delete?</Typography>
      )}
    </ManageDataDialog>
  )
}

export default ManageUsers

import axios from 'axios'
import { BASE_API_URL } from '../constants'

export const request = function (options) {
  const authHeaders = {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`
  }
  const client = axios.create({
    baseURL: BASE_API_URL,
    headers: options.headers || authHeaders
  })

  const onSuccess = function (response) {
    if (options.handleHeaders === 1) {
      if (response && response.data && response.data.user) {
        sessionStorage.setItem('token', JSON.stringify(response.data.token))
        const user = JSON.stringify(response.data.user)
        sessionStorage.setItem('user', user)
      }
    }
    if (options.handleHeaders === 0) {
      localStorage.clear()
    }
    const resp = response.data
    if (resp.hasOwnProperty('success')) {
      return resp
    } else {
      return { ...resp, success: true }
    }
  }

  const onError = function (error) {
    console.log('Error', error.message)
    if (options.handleHeaders === 0) {
      localStorage.clear()
    }
    if (error.response) {
      if (error.response.status === 401 && options.handleHeaders !== 1) {
        window.location.href = '/auth/signin'
      }
    }
    const errorResp =
      error.response && error.response.data && error.response.data.error
        ? error.response.data.error.message
        : 'Something went wrong try again'
    return { success: false, error: errorResp }
  }

  return client(options).then(onSuccess).catch(onError)
}

export default request

import React, { useContext, useState } from 'react'
import ManageDataDialog from '../../components/universal/dialog_component'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import DataServices from '../../lib/services/api/data_services'
import { ToastMessageContext } from '../../lib/contexts/message_context'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

function ManageLabels (props) {
  const { handleClose, action, currentSelection } = props
  const [loading, setLoading] = useState(false)
  const message = useContext(ToastMessageContext)

  const handleSubmit = formData => {
    if (action === 'update') {
      formData['id'] = currentSelection.id
      setLoading(true)
      DataServices.updateData(formData, '/admin/buttons').then(res => {
        if (res.success) {
          setLoading(false)
          message.showToastMessage({
            message: 'Label Updated successfully',
            variant: 'success'
          })
          handleClose()
        } else {
          setLoading(false)
          message.showToastMessage({
            message: res.error ? res.error : 'Something went wrong. Try Again',
            variant: 'error'
          })
        }
      })
    }
  }
  console.log(currentSelection)
  return (
    <ManageDataDialog
      title={`${action} Label Details`}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      loading={loading}
      type={action}
    >
      {action !== 'delete' ? (
        <Grid container spacing={4} alignItems={'center'} justify={'center'}>
          <Grid item xs={12}>
            <TextField
              name={'button_label'}
              label={'Button Label'}
              required
              fullWidth
              variant='outlined'
              defaultValue={currentSelection.button_label}
            />
            <TextField
              name={'cause1'}
              label={'Cause 1'}
              required
              fullWidth
              variant='outlined'
              defaultValue={currentSelection.cause1}
            />
            <TextField
              name={'cause2'}
              label={'Cause 2'}
              required
              fullWidth
              variant='outlined'
              defaultValue={currentSelection.cause2}
            />
            <TextField
              name={'cause3'}
              label={'Cause 3'}
              required
              fullWidth
              variant='outlined'
              defaultValue={currentSelection.cause3}
            />
            <TextField
              name={'cause4'}
              label={'Cause 4'}
              required
              fullWidth
              variant='outlined'
              defaultValue={currentSelection.cause4}
            />
            <TextField
              name={'cause5'}
              label={'Cause 5'}
              required
              fullWidth
              variant='outlined'
              defaultValue={currentSelection.cause5}
            />
            <InputLabel id='evolution'>Evolution</InputLabel>
            <Select
              defaultValue={currentSelection.evolution}
              labelId='evolution'
              id='evolution'
              name='evolution'
              label={'evolution'}
              placeholder={'evolution'}
              fullWidth
            >
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </Select>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='subtitle2'> Confirm delete?</Typography>
      )}
    </ManageDataDialog>
  )
}

export default ManageLabels

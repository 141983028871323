import React, { useContext, useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import tableIcons, {
  options
} from '../../../components/universal/table_attributes'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import ViewIcon from '@material-ui/icons/Visibility'
import AnnounceIcon from '@material-ui/icons/AddAlert'
import DataServices from '../../../lib/services/api/data_services'
import { ToastMessageContext } from '../../../lib/contexts/message_context'
import Typography from '@material-ui/core/Typography'
import ManageUsers from '../manage_user'
import Button from '@material-ui/core/Button'
import useDebounce from '../../../lib/utils/debounce'
import NotificationMessage from '../notification_trigger'
import SettingsIcon from '@material-ui/icons/Settings'
import UserEvolutionButtons from './evolution_buttons'

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    width: '90%',
    margin: '2%'
  },
  evolWrapper: {
    width: '100%',
    margin: '2%'
  }
}))

function UserListing (props) {
  const classes = useStyles()
  const [users, setUsers] = useState([])
  const groupId = props.match.params.groupId
  const role = props.match.params.role

  const [loading, setLoading] = useState(false)
  const [showSendNotification, setShowSendNotification] = useState(false)
  const [action, setAction] = useState(null)
  const [currentSelection, setCurrentSelection] = useState({})
  const [searchText, setSearchText] = useState()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(10)
  const debouncedSearch = useDebounce(searchText, 700)
  const tableOptions = {
    ...options,
    page: page || 1,
    total: total,
    pageSize: pageSize,
    searchText: searchText
  }

  const message = useContext(ToastMessageContext)

  const getUsers = () => {
    setLoading(true)
    const params = {
      page: page,
      limit: pageSize,
      search_keyword: debouncedSearch,
      user_group: groupId,
      role: role
    }
    DataServices.getData(params, '/admin/users').then(res => {
      if (res.success) {
        setUsers(res.users)
        setTotal(+res.total)
        setLoading(false)
      } else {
        message.showToastMessage({
          message: 'Something went wrong. Try Again',
          variant: 'error'
        })
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    getUsers()
  }, [page, pageSize, debouncedSearch])

  const genderMap = { 0: 'Female', 1: 'Male', 2: 'Others' }
  const verifiedMap = { 0: 'Pending', 1: 'Verified' }

  const columns = [
    {
      title: 'Name',
      field: 'name',
      width: '25%',
      headerStyle: {
        paddingLeft: '3%'
      },
      cellStyle: {
        paddingLeft: '3%'
      },
      render: rowData => <span>{rowData.name || '-'}</span>
    },
    {
      title: 'User Email',
      field: 'email',
      width: '30%',
      render: rowData => <span>{rowData.email || '-'}</span>
    },
    {
      title: 'Age',
      field: 'age',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      }
    },
    {
      title: 'Evolution',
      field: 'current_evolution',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      }
    },
    {
      title: 'Gender',
      field: 'gender',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      },
      render: rowData => (
        <span>{rowData.gender ? genderMap[rowData.gender] : 'N/A'}</span>
      )
    },
    {
      title: 'Zipcode',
      field: 'zipcode',
      render: rowData => (
        <span style={{ textTransform: 'uppercase' }}>
          {rowData.zipcode || '-'}
        </span>
      )
    },
    {
      title: 'Role',
      field: 'role',
      render: rowData => (
        <span style={{ textTransform: 'capitalize' }}>
          {rowData.role.replace('_', ' ') || '-'}
        </span>
      )
    },
    {
      title: 'Verified',
      field: 'is_verified',
      render: rowData => (
        <Button
          component={'div'}
          disabled
          size={'small'}
          variant={'outlined'}
          className={rowData.is_verified === 1 ? 'positive' : 'negative'}
        >
          {verifiedMap[rowData.is_verified]}
        </Button>
      )
    }
  ]

  const handleClose = data => {
    setAction(null)
    setCurrentSelection({})
    getUsers()
  }

  return (
    <div className={classes.tableWrapper}>
      <div className={'align-corner'}>
        <Typography
          component={'h2'}
          style={{ width: '50%' }}
          className={'title'}
        >
          {role ? 'Sesamind Admins' : `Users`}
        </Typography>
        <div style={{ width: '100%', textAlign: 'right', margin: '15px 0' }}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => setAction('create')}
          >
            {role ? 'Add New Admin' : `Add new User`}
          </Button>
        </div>
      </div>
      <MaterialTable
        style={{ boxShadow: '1px 0px 20px 5px #7052fb10' }}
        icons={tableIcons}
        title={''}
        columns={columns}
        data={users || []}
        isLoading={loading}
        options={tableOptions}
        localization={{
          toolbar: {
            searchPlaceholder: 'Search user by email'
          },
          body: {
            emptyDataSourceMessage: 'No users found',
            filterRow: {
              filterTooltip: 'Filter'
            }
          },
          header: {
            actions: 'Actions'
          }
        }}
        onChangePage={page => {
          setPage(page)
        }}
        onChangeRowsPerPage={pageSize => {
          setPageSize(pageSize)
        }}
        onSearchChange={search => {
          setSearchText(search)
        }}
        totalCount={+total}
        page={+page}
        actions={[
          {
            icon: () => <EditIcon fontSize='small' />,
            tooltip: 'Edit',
            onClick: (event, rowData) => {
              setCurrentSelection(rowData)
              setAction('update')
            }
          },
          {
            icon: () => <ViewIcon fontSize='small' />,
            tooltip: 'View',
            onClick: (event, rowData) => {
              props.history.push(`/users/${rowData.id}/details`)
            }
          },
          {
            icon: () => <AnnounceIcon fontSize='small' />,
            tooltip: 'Notify',
            onClick: (event, rowData) => {
              setCurrentSelection(rowData)
              setShowSendNotification(true)
            }
          },
          {
            icon: () => <SettingsIcon color={'disabled'} />,
            tooltip: 'Evolutions',
            onClick: (event, rowData) => {
              setCurrentSelection(rowData)
              setAction('view')
            }
          }
        ]}
      />
      <div className={classes.evolWrapper}>
        {action && action === 'view' && (
          <UserEvolutionButtons
            groupId={currentSelection.id}
            groupName={currentSelection.name}
          />
        )}
      </div>
      {action && action !== 'view' && (
        <ManageUsers
          action={action}
          handleClose={handleClose}
          currentSelection={currentSelection}
          role={role}
          groupId={groupId}
        />
      )}
      {showSendNotification && (
        <NotificationMessage
          topic={null}
          handleClose={() => setShowSendNotification(false)}
          currentSelection={currentSelection}
        />
      )}
    </div>
  )
}

export default UserListing

import React, { useEffect } from 'react'
import withDefaultLayout from '../../Layout'
import PageNotFound from '../../../components/universal/page_not_found'

export const requireAuth = allowedRoles => ComposedComponent => {
  function Authentication (props) {
    useEffect(() => {
      const user = JSON.parse(sessionStorage.getItem('user'))
      const userRole = user ? user.role : null
      if (!userRole) {
        props.history.push('/auth/signin')
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    })
    const user = JSON.parse(sessionStorage.getItem('user'))
    const userRole = user ? user.role : ''
    if (allowedRoles.includes(userRole)) {
      const ComponentWithLayout = withDefaultLayout(ComposedComponent)
      return <ComponentWithLayout {...props} userRole={userRole} user={user} />
    } else {
      return <PageNotFound />
    }
  }

  return Authentication
}

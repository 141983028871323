import React from 'react'
import Divider from '@material-ui/core/Divider'

import './message.scss'

const User = props => {
  return (
    <div className='user-list' onClick={props.userClick}>
      <div className='img-block'>
        {/* <img src="/demo.jpg" alt="" /> */}
        <span className='profile_name'>{props.name[0] ?? '-'}</span>
      </div>
      <p>{props.name ?? ''}</p>
    </div>
  )
}

const LayoutMessage = props => {
  return (
    <div className='left-m-block'>
      <div className='search-block padding-10'>
        {props.userRole === 'super_admin' && (
          <input
            name='searh'
            placeholder='Search...'
            onKeyUp={props.searchUser}
          />
        )}
      </div>
      <Divider />
      <h2 className='typo-h2'>User List:</h2>
      <div className='scroll-block left-userblock'>
        {props.userList?.length > 0 ? (
          <>
            {props.userList.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <User
                    name={item.name}
                    userClick={() => props.userDetailsClick(item)}
                  />
                </React.Fragment>
              )
            })}
          </>
        ) : (
          <p className='no-data-found'>No user found</p>
        )}
      </div>
    </div>
  )
}

export default LayoutMessage

import React, {useEffect, useState} from "react";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import CountUp from "react-countup";


function ButtonClickStats(props){

    const {stats} = props;
    const [currentStat,setCurrentStat] = useState({
        button1:0,
        button2:0,
        button1_label:"Button 1",
        button2_label:"Button 2",
    })

    useEffect(()=>{
        let newStats = {...currentStat};
        const clicks = stats.button_clicks;
        const button1Label = stats.button_1_label?stats.button_1_label:"Button 1";
        const button2Label = stats.button_2_label?stats.button_2_label:"Button 2";
        if(clicks && clicks.length > 0){
            newStats['button1'] = clicks[0]?.total;
            newStats['button2'] = clicks[1]?.total;

            //Check if logic needed in edge cases for other evolutions
            /*for(let i=0; i<clicks.length; i++){
                if(clicks[i].button.toLowerCase() === button1Label.toLowerCase()){
                    newStats['button1'] = clicks[i].total;
                }
                if(clicks[i].button.toLowerCase() === button2Label.toLowerCase()){
                    newStats['button2'] = clicks[i].total;

                }
            }*/
        }

        setCurrentStat({...newStats,button1_label:button1Label,button2_label:button2Label});
    },[stats.button_clicks])

    return (
       <Grid container spacing={2} alignItems={"flex-start"} justify={"center"}>
           <Grid item md={12} xs={12}>
               <Typography style={{padding:'10px 25px 5px 25px',color:'#000',fontSize:'1em'}} variant={"subtitle2"}>Button Clicks</Typography>
           </Grid>
           <Grid item md={6} xs={6} align={"center"}>
               <Typography align={"center"} className={"number circle"} gutterBottom><CountUp end={currentStat.button1} delay={1}/></Typography>
               <Typography align={"center"}>{currentStat.button1_label}</Typography>
           </Grid>
           <Grid item md={6} xs={6} align={"center"}>
               <Typography align={"center"} className={"number circle"} color={"primary"} gutterBottom><CountUp end={currentStat.button2} delay={1}/></Typography>
               <Typography align={"center"}>{currentStat.button2_label}</Typography>
           </Grid>
       </Grid>
    )
}

export default ButtonClickStats;


import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {validateEmail} from "../../lib/utils/common_utils";
import UserIcon from "@material-ui/icons/PersonOutlined";
import useAuthStyles from "./auth_styles";
import DataServices from "../../lib/services/api/data_services";
import CssBaseline from "@material-ui/core/CssBaseline";


export default function ForgotPasswordRequest(props) {
    const classes = useAuthStyles();
    const [error, setError] = useState({});
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const session = props.match.params.session;

    useEffect(()=>{
        if(session) {
            const user = JSON.parse(sessionStorage.getItem('user'));
            requestResetPassword(user.email);
        }
    },[session])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = formData.get('eid');
        if (!validateEmail(email.trim())) {
            setError({...error,email:true});
            return;
        }

        requestResetPassword(email);
    };

    const requestResetPassword = (email) => {
        const postData = {
            email: email,
        }
        setLoading(true);
        DataServices.postData(postData,'/auth/forgot-password').then((res)=>{
            if(res.success){
                setMessage({message:<span>Steps to Reset your account password has been sent to your registered email address</span>, success: true});
            }
            else {
                setMessage({message:(res.error && res.error.message) ? res.error.message :<span>Entered username not found in the system<br/> Kindly retry with your registered email address!</span>, success: false});
            }
            setLoading(false);
        })
    }

    const clearError = () => {
        setError({});
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.leftWrapper}>
                <div style={{textAlign:'center'}}>
                    <img className={classes.desktop} src={"/sample_app_screen.png"}/>
                    <div className={'two-column'}>
                        <div className={'half-wide'}>
                            <a href={'#'} target={"_blank"} rel={"noopener noreferrer"}><img src={'/get_on_google_play.svg'} height={'60px'} alt={"Sensamind on Google Play"}/></a>
                        </div>
                        <div className={'half-wide'}>
                            <a href={'#'} target={"_blank"} rel={"noopener noreferrer"}><img src={'/get_on_app_store.svg'} height={'60px'} alt={"Sensamind on Apple store"}/></a>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={8} md={5} className={classes.wrapper}>
                <div className={classes.paper}>
                    <div style={{textAlign:'center'}}>
                        <a href={'https://sensamind.com'} target={"_blank"} rel={"noopener noreferrer"}><img src={'/sensamind.png'} alt="Sensamind logo"/></a>
                    </div>
                    {!!!message ? <form className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
                            <Typography className={classes.title}>Request Password Change</Typography>
                            <Typography variant={"subtitle1"} gutterBottom>Please enter your registered Email id to get password reset link</Typography>
                            <FormControl fullWidth className={classes.formField}>
                                <OutlinedInput
                                    required
                                    name="eid"
                                    autoComplete="new-password"
                                    placeholder="Registered email id"
                                    autoFocus
                                    error={!!error.email}
                                    onFocus={clearError}
                                    startAdornment={<InputAdornment position="start">
                                        <UserIcon color={"primary"}/>
                                    </InputAdornment>
                                    }
                                />
                                {!!error.email && <FormHelperText error={true}>{error.email ? "Please enter your registered email id" : null}</FormHelperText>}
                            </FormControl>
                            <div className={classes.secondaryAction}>
                                <span style={{cursor: 'pointer',}} onClick={() => props.history.push('/auth/signin')}>
                                    Login instead?
                                </span>
                            </div>
                            <Button
                                type="submit"
                                fullWidth
                                className={classes.submit}
                                disabled={loading}
                                variant={"contained"}
                                color={"primary"}
                            >
                                Request
                            </Button>
                            <div style={{textAlign: "center", marginTop: '15px'}}>
                                <span style={{marginTop: '20px', color: 'red'}}>{error.message || null}</span>
                            </div>
                        </form>:
                        <form className={classes.form}>
                            <Typography align="center" variant="subtitle1" gutterBottom
                                        className={classes.message}>
                                {message.message}
                            </Typography>
                            {!message.success && <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => setMessage(null)}
                            >
                                Retry
                            </Button>}
                            {message.message &&
                            <Typography align="center" variant="subtitle2"
                                        style={{cursor: 'pointer',margin:'20px 0'}}
                                        onClick={() => props.history.push('/auth/signin')}>
                                Sign in
                            </Typography>
                            }
                        </form>}
                </div>
            </Grid>
        </Grid>

    );
}